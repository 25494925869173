import React, { useEffect, useState } from "react";
// https://actioncoachassessments.com/Custom/DS/Common/Assess/assessment-rdo.aspx?es=cs3eUv3F6nkv9FDZrCBLM8oTdCNMriSGqZsvOX9KR0jlFoqAxCs2h9uIT2aDJSAkUjqm1WIVVSnzMbIotfN7Kg==

function Page(props) {
    const [count, setCount] = useState(0);

    useEffect(() => {
        //Implementing the setInterval method
        const interval = setInterval(async () => {
            setCount(count + 1);
            console.log(count);
        }, 3000);

        //Clearing the interval
        return () => clearInterval(interval);
    }, [count]);
    return (
        <iframe
            src="https://actioncoachassessments.com/Custom/DS/Common/Assess/assessment-rdo.aspx?es=MStUIS+vvmUbUEq4CUZhrzB2XKZtHdnX/ByOEp8iZ3U5vVjJ4X2uAf+8uhZcNvOaqelugRi1Wt1VTbSpUcUTcQ=="
            height="700"
            onLoad={(e) => {
                console.log("Ifarme", e.pageX, e.pageY);
            }}
        ></iframe>
    );
}

export default Page;
