import React, { useContext, useEffect, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import { Card, Space, Spin, Table, Tag } from "antd";
import { RealmContext } from "../context/realmProvider";
import {
  fetchCombo,
  selectCombo,
  selectUser,
  selectUsers,
} from "../redux/assessmentsSlice";
import { useDispatch, useSelector } from "react-redux";

function Combo(props) {
  const [loading, setLoading] = useState(false);
  const currentUser = useSelector(selectUser);
  const { mongo, user } = useContext(RealmContext);

  const combo = useSelector(selectCombo);
  const isAdmin = currentUser?.group == "Admin";
  const columns = [
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Ngôn ngữ",
      dataIndex: "language",
      key: "language",
    },
    {
      title: "Credit",
      dataIndex: "credit",
      key: "credit",
    },
    {
      title: "Bài đánh giá",
      dataIndex: "type",
      key: "type",
      align: "center",
      width: "180px",
      render: (_, { type }) => (
        <>
          {type
            ? type.map((t) => {
                return (
                  <Tag style={{ cursor: "pointer" }} color="blue" key={t}>
                    {t}
                  </Tag>
                );
              })
            : ""}
        </>
      ),
    },

    {
      title: "Tags",
      dataIndex: "tags",
      key: "tags",
      align: "center",
      width: "180px",
      render: (_, { tags }) => (
        <>
          {tags
            ? tags.map((tag) => {
                return (
                  <Tag style={{ cursor: "pointer" }} color="blue" key={tag}>
                    {tag}
                  </Tag>
                );
              })
            : ""}
        </>
      ),
    },
  ];

  useEffect(() => {
    //console.log(combo);
  }, [combo]);

  return (
    <Spin spinning={loading} size="large">
      <Card>
        <Link to="/">Back</Link>
        <Table
          rowKey="_id"
          className="pt-3"
          columns={columns}
          dataSource={combo}
          pagination={{
            pageSizeOptions: [10, 20, 50, 100, 200],
            showSizeChanger: true,
            showQuickJumper: true,
          }}
        />
      </Card>
    </Spin>
  );
}

export default Combo;
