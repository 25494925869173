import React from "react";
import { useLocation } from "react-router-dom";

function NotFound(props) {
  let location = useLocation();
  console.log(location);
  return <div>NotFound</div>;
}

export default NotFound;
