import React, { useContext, useState } from "react";
import { selectUser, selectUsers } from "../redux/assessmentsSlice";
import { useSelector } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import { Button, Card, InputNumber, Select, Space, Spin, Tabs } from "antd";
import { RealmContext } from "../context/realmProvider";

function Admin(props) {
  const [loading, setLoading] = useState(false);
  const currentUser = useSelector(selectUser);
  const [fromUser, setFromUser] = useState();
  const [toUser, setToUser] = useState();
  const [credit, setCredit] = useState(1);
  const users = useSelector(selectUsers);
  const { user } = useContext(RealmContext);
  const onChange = (key) => {
    console.log(key);
  };
  const onTransfer = async () => {
    setLoading(true);
    await user.functions.tranferCredit({ fromUser, toUser, credit });
    setLoading(false);
  };
  const onChangeCredit = (value) => {
    setCredit(value);
  };
  const onChangeFrom = (value) => {
    if (value == toUser) setToUser("");
    setFromUser(value);
  };
  const onChangeTo = (value) => {
    setToUser(value);
  };
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  const items = [
    {
      key: "1",
      label: "Chuyển Credit",
      children: (
        <Space>
          <Select
            showSearch
            placeholder="Chuyển từ"
            optionFilterProp="children"
            className="min-w-[200px]"
            allowClear
            value={fromUser}
            onChange={onChangeFrom}
            filterOption={filterOption}
            options={users
              ?.filter((u) => u.credit > 0)
              .map((u) => ({
                value: u.userID,
                label: u.fullname + " (" + u.credit + "Credit)",
              }))}
          />
          <InputNumber
            value={credit}
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={(value) => value.replace(/\[^a-zA-Z]\s?|(,*)/g, "")}
            onChange={onChangeCredit}
          />
          <Select
            showSearch
            placeholder="Chuyển đến"
            optionFilterProp="children"
            className="min-w-[200px]"
            allowClear
            value={toUser}
            onChange={onChangeTo}
            filterOption={filterOption}
            options={users
              ?.filter((u) => u.userID != fromUser)
              .map((u) => ({
                value: u.userID,
                label: u.fullname + " (" + u.credit + "Credit)",
              }))}
          />
          <Button type="primary" onClick={onTransfer}>
            Chuyển
          </Button>
        </Space>
      ),
    },
    {
      key: "2",
      label: "Cập nhật Credit",
      children: <Space>Đang cập nhật</Space>,
    },
    {
      key: "3",
      label: "Báo cáo",
      children: <Space>Đang cập nhật</Space>,
    },
  ];
  if (currentUser?.group != "Admin") {
    return <Navigate to="/" replace />;
  }
  return (
    <Spin spinning={loading} size="large">
      <Card>
        <Link to="/">Back</Link>
        <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
      </Card>
    </Spin>
  );
}

export default Admin;
