import {
    CheckOutlined,
    DeleteOutlined,
    EditOutlined,
    FilePdfOutlined,
    LinkOutlined,
    SendOutlined,
    UnorderedListOutlined,
    UsergroupAddOutlined,
} from "@ant-design/icons";
import { Button, Space, Table, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";

function LeadershipEffectiveness(props) {
    const { short } = useParams();
    const [compute, setCompute] = useState();

    return (
        <div>
            {" "}
            <div className="bg-[#1F222D] m-[0 auto]">
                <center>
                    <img
                        alt=""
                        className="w-full max-w-[550px] h-auto m-[100px]"
                        src="https://actioncoachassessments.com/UploadArea/308/2924-A2/Assessments-24x7-Logo-Vector-1-02-1024x163.png"
                    />
                </center>
            </div>
            <div className="max-w-[1180px] mx-auto px-5 font-pop">
                <h3 className="font-bold text-[32px]">Vu Nguyễn</h3>
                <div className="flex flex-row gap-7">
                    <div className="basis-1/2">
                        <div className="rounded-t-[.25rem] bg-[#1d6ccd] text-white px-5 py-3">
                            <UnorderedListOutlined /> Đánh giá của bạn
                        </div>
                        <div
                            className="rounded-b-[.25rem] border border-solid
                          border-[#00000020] px-5 py-3"
                        >
                            <span className="text-[18pt] font-bold">
                                Leadership Effectiveness 360 - Vietnamese
                            </span>
                            <br />
                            <span className="text-[10pt] uppercase">
                                Đã hoàn thành 29/07/2024 12:05:49 CH
                            </span>
                            <CheckOutlined className="float-right text-[26pt] text-[#44c344]" />
                            <div className="clear-both"></div>
                        </div>
                    </div>
                    <div className="basis-1/2">
                        <div className="bg-[#1d6ccd] text-white px-5 py-3">
                            <FilePdfOutlined /> Đánh giá của bạn
                        </div>
                        <div
                            className="rounded-b-[.25rem] border border-solid
                          border-[#00000020] px-5 py-3"
                        >
                            <span className="text-[18pt] font-bold">
                                Leadership Effectiveness 360 - Vietnamese
                            </span>
                            <br />
                            <span className="text-[10pt] uppercase">
                                Đã hoàn thành 29/07/2024 12:05:49 CH
                            </span>
                            <Tooltip title="Tải xuống tệp PDF">
                                <img
                                    className="float-right w-12 h-12"
                                    src="https://actioncoachassessments.com/enduseradmin/Images/pdficon.png"
                                />
                            </Tooltip>
                            <Tooltip title="Xem báo cáo">
                                <img
                                    className="float-right w-12 h-12"
                                    src="https://actioncoachassessments.com/enduseradmin/Images/viewreport.png"
                                />
                            </Tooltip>
                            <div className="clear-both"></div>
                        </div>
                    </div>
                </div>
                <div className="mt-4">
                    <div className="rounded-t-[.25rem] bg-[#1d6ccd] text-white px-5 py-3">
                        <UsergroupAddOutlined /> Mời Người quan sát
                    </div>

                    <div
                        className="rounded-b-[.25rem] border border-solid
                          border-[#00000020] px-5 py-3"
                    >
                        <p>
                            Các bài đánh giá của bạn cần có Người quan sát. Vui
                            lòng làm theo các bước này để đảm bảo bạn nhận được
                            phản hồi phù hợp!
                        </p>
                        <div className="flex">
                            <div className="flex-none w-[75px] ">
                                <div className="text-white text-center text-[22pt] font-bold rounded-lg w-12 h-12 bg-[#1d6ccd]">
                                    1
                                </div>
                            </div>
                            <div className="flex-auto w-64 ">
                                <span className="font-bold">
                                    Đảm bảo rằng bạn hoàn thành bài đánh giá của
                                    mình!
                                </span>
                                <br />
                                <span>
                                    Nhấp vào nút Hoàn thành Đánh giá phía trên.
                                </span>
                            </div>
                        </div>
                        <div className="flex mt-5">
                            <div className="flex-none w-[75px] ">
                                <div className="text-white text-center text-[22pt] font-bold rounded-lg w-12 h-12 bg-[#1d6ccd]">
                                    2
                                </div>
                            </div>
                            <div className="flex-auto w-64 ">
                                <span className="font-bold">
                                    Chọn cách mời những Người quan sát của bạn.
                                </span>
                                <br />
                                <span>
                                    Có hai cách để mời Người quan sát, hãy chọn
                                    phương án phù hợp nhất với nhu cầu của bạn.
                                </span>
                                <ul className="list-none mb-0 pl-0 flex">
                                    <li
                                        className="text-[#0000ff] font-extrabold border border-solid
                          border-[#00000020] mb-[-1px] px-4 py-2 min-h-[100px] cursor-pointer"
                                    >
                                        <span className="text-lg">
                                            Phương án 1
                                        </span>
                                        <br />
                                        <span>
                                            Tôi muốn gửi lời mời bằng email của
                                            tôi.
                                        </span>
                                    </li>
                                    <li
                                        className="text-[#008001] font-extrabold border border-solid
                          border-[#00000020] mb-[-1px] px-4 py-2 min-h-[100px] cursor-pointer"
                                    >
                                        <span className="text-lg">
                                            Phương án 2
                                        </span>
                                        <br />
                                        <span>
                                            Tôi muốn gửi lời mời bằng cách sử
                                            dụng biểu mẫu trên trang này.
                                        </span>
                                    </li>
                                </ul>
                                <div
                                    className="p-3 border border-solid
                          border-[#00000020]"
                                >
                                    <p>
                                        Mời người quan sát hoàn thành bài đánh
                                        giá. Tạo email mới và gửi cho người quan
                                        sát của bạn. Chúng tôi khuyên bạn nên
                                        gửi một email tới nhiều người nhận ở
                                        trường Người nhận hoặc trường CC. Bạn
                                        chỉ cần sao chép biểu mẫu của chúng tôi,
                                        trong đó có liên kết dành cho người quan
                                        sát cụ thể của bạn, và sử dụng liên kết
                                        làm nội dung bắt đầu cho email. Để xem
                                        biểu mẫu, nhấp vào nút Xem biểu mẫu ở
                                        phía bên phải. Nếu bạn chọn không sử
                                        dụng biểu mẫu của chúng tôi, hãy sao
                                        chép liên kết dành cho người quan sát
                                        dưới đây, dán vào email mới và viết nội
                                        dung theo ý của riêng bạn.
                                    </p>
                                    <p>
                                        <span className="font-bold">
                                            LƯU Ý:
                                        </span>{" "}
                                        Nếu bạn chọn phương án này, người quan
                                        sát sẽ tự chọn mối quan hệ báo cáo của
                                        họ. Ví dụ, cấp dưới, đồng cấp, v.v., nếu
                                        phù hợp. (Nếu cần, bạn có thể chỉnh sửa
                                        nội dung này ở Bước 3)
                                    </p>
                                    <div
                                        className="rounded py-3 px-5 border border-solid
                          border-[#00000020]"
                                    >
                                        <button className="bg-white border-solid border rounded py-[.25rem] px-[.5rem] cursor-pointer float-right">
                                            Sao chép liên kết
                                        </button>
                                        <span className="font-bold">
                                            Leadership Effectiveness 360 -
                                            Vietnamese
                                        </span>
                                        <br />
                                        <span>
                                            https://actioncoachassessments.com/EndUserAdmin/?gj%7dpXx+9Zhm)8==R
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex mt-5">
                            <div className="flex-none w-[75px] ">
                                <div className="text-white text-center text-[22pt] font-bold rounded-lg w-12 h-12 bg-[#1d6ccd]">
                                    3
                                </div>
                            </div>
                            <div className="flex-auto w-64 ">
                                <span className="font-bold">
                                    Theo dõi phản hồi từ Người quan sát của bạn.
                                </span>
                                <br />
                                <span>
                                    Dưới đây là danh sách những Người quan sát
                                    mà bạn đã mời. Bạn có thể theo dõi trạng
                                    thái hoàn thành, chỉnh sửa thông tin (bao
                                    gồm cả mối quan hệ báo cáo), gửi lời nhắc,
                                    cũng như xóa Người quan sát. Đảm bảo rằng
                                    tất cả Người quan sát của bạn đã hoàn thành
                                    bài đánh giá của họ trước khi bạn tải xuống
                                    báo cáo cuối cùng.
                                </span>
                                <Table
                                    className="mt-5"
                                    bordered
                                    size="small"
                                    columns={[
                                        {
                                            title: "Công cụ",
                                            dataIndex: "tool",
                                            render: () => {
                                                return (
                                                    <Space size={3}>
                                                        <Button
                                                            type="primary"
                                                            icon={
                                                                <LinkOutlined />
                                                            }
                                                            size="small"
                                                        ></Button>
                                                        <Button
                                                            type="primary"
                                                            icon={
                                                                <EditOutlined />
                                                            }
                                                            size="small"
                                                        ></Button>
                                                        <Button
                                                            type="primary"
                                                            icon={
                                                                <SendOutlined />
                                                            }
                                                            size="small"
                                                        ></Button>
                                                    </Space>
                                                );
                                            },
                                        },
                                        {
                                            title: "Người quan sát (Tên và email)",
                                            dataIndex: "email",
                                        },
                                        {
                                            title: "Đánh giá Hoàn thành/Chưa hoàn thành",
                                            dataIndex: "status",
                                        },
                                        {
                                            title: "",
                                            dataIndex: "delete",
                                            width: 50,
                                            render: () => {
                                                return (
                                                    <Button
                                                        type="primary"
                                                        danger
                                                        icon={
                                                            <DeleteOutlined />
                                                        }
                                                        size="small"
                                                    ></Button>
                                                );
                                            },
                                        },
                                    ]}
                                    dataSource={[
                                        {
                                            email: "vunh2301@gmail.com",
                                            status: "Chưa hoàn thành",
                                        },
                                    ]}
                                />
                                <Button className="float-right mt-3">
                                    Xem email nhắc nhở
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="pt-10 pb-2">
                    <p className="text-center">
                        <span>
                            <strong>Hỗ trợ khách hàng</strong>
                        </span>
                        <strong>: </strong>
                        <span>
                            <a href="mailto:support@a247.io">support@a247.</a>
                            <a href="mailto:support@a247.io">io</a>
                        </span>
                    </p>
                </div>
            </div>
        </div>
    );
}

export default LeadershipEffectiveness;
