import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import MasterLayout from "./layout/MasterLayout";
import NotFound from "./pages/NotFound";
import { RealmProvider } from "./context/realmProvider";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import Dashboard from "./pages/Dashboard";
import "./index.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ResetPassword from "./components/login/ResetPassword";
import "antd/dist/reset.css";
import { App } from "antd";
import Admin from "./pages/Admin";
import Report from "./pages/Report";
import Combo from "./pages/Combo";
import Page from "./pages/Page";
import Short from "./pages/Short";
import Shortv2 from "./pages/Shortv2";
import LeadershipEffectiveness from "./pages/LeadershipEffectiveness";
import { HelmetProvider } from "react-helmet-async";
const helmetContext = {};
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <HelmetProvider context={helmetContext}>
        <RealmProvider id="wowed-ijfty">
            <Provider store={store}>
                <App>
                    <BrowserRouter>
                        <Routes>
                            <Route element={<MasterLayout />}>
                                <Route path="/" element={<Dashboard />}></Route>
                                <Route
                                    path="/dashboard"
                                    element={<Dashboard />}
                                ></Route>
                                <Route path="/page" element={<Page />}></Route>
                                <Route
                                    path="/admin"
                                    element={<Admin />}
                                ></Route>
                                <Route
                                    path="/report"
                                    element={<Report />}
                                ></Route>
                                <Route
                                    path="/combo"
                                    element={<Combo />}
                                ></Route>
                            </Route>
                            <Route
                                path="/reset"
                                element={<ResetPassword />}
                            ></Route>
                            <Route path="/:short" element={<Short />}></Route>
                            <Route
                                path="/360/:short"
                                element={<LeadershipEffectiveness />}
                            ></Route>
                            <Route
                                path="/s/:short"
                                element={<Shortv2 />}
                            ></Route>
                            <Route path="*" element={<NotFound />} />
                        </Routes>
                        <ToastContainer />
                    </BrowserRouter>
                </App>
            </Provider>
        </RealmProvider>
    </HelmetProvider>
);
