import React, { useContext, useEffect, useRef, useState } from "react";
import { SendOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Form,
  Input,
  Popconfirm,
  Select,
  Tooltip,
  Empty,
  Card,
  Row,
  Col,
  Radio,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../redux/assessmentsSlice";
const EditableContext = React.createContext(null);
const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};
const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);
  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };
  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({
        ...record,
        ...values,
      });
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };
  let childNode = children;
  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }
  return <td {...restProps}>{childNode}</td>;
};
function MultipleAddAssessment(props) {
  const [dataSource, setDataSource] = useState([
    {
      key: "0",
      email: "justin@a247.vn",
      firstname: "Nguyễn Hoàng",
      lastname: "Vũ",
    },
    {
      key: "1",
      email: "andy@a247.vn",
      firstname: "Nguyễn Thành",
      lastname: "Nhân",
    },
  ]);
  const [count, setCount] = useState(2);
  const [assessmentState, setAssessmentState] = useState();
  const [form] = Form.useForm();
  const currentUser = useSelector(selectUser);

  const handleFinish = () => {};
  const handleChange = () => {};

  const handleDelete = (key) => {
    const newData = dataSource.filter((item) => item.key !== key);
    setDataSource(newData);
  };
  const defaultColumns = [
    {
      title: "Email",
      dataIndex: "email",
      width: "30%",
      editable: true,
    },
    {
      title: "Họ & Tên lót",
      dataIndex: "firstname",
      editable: true,
    },
    {
      title: "Tên",
      dataIndex: "lastname",
      editable: true,
    },
    {
      title: "#",
      dataIndex: "operation",
      render: (_, record) =>
        dataSource.length >= 1 ? (
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => handleDelete(record.key)}
          >
            <a>Delete</a>
          </Popconfirm>
        ) : null,
    },
  ];
  const handleAdd = () => {
    const newData = {
      key: count,
      name: `Edward King ${count}`,
      age: "32",
      address: `London, Park Lane no. ${count}`,
    };
    setDataSource([...dataSource, newData]);
    setCount(count + 1);
  };
  const handleSave = (row) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSource(newData);
  };
  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };
  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });
  return (
    <div>
      <Row gutter={20}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <h3>Thông tin bài đánh giá:</h3>
          <Form
            form={form}
            layout="vertical"
            name="multipleAddAssessment"
            onFinish={handleFinish}
            onValuesChange={handleChange}
            initialValues={{
              email: "",
              type: [],
              language: "Vietnamese",
              gender: "Anh/Chị",
              emailCC: "",
            }}
          >
            <Row gutter={20}>
              <Col span={16}>
                <Form.Item
                  name="type"
                  rules={[
                    {
                      required: true,
                      message: "Vui chọn bài Đánh giá!",
                    },
                  ]}
                >
                  <Checkbox.Group>
                    <Row>
                      <Col span={12}>
                        <Checkbox value="DISC" style={{ lineHeight: "32px" }}>
                          DISC{" "}
                          <Tooltip
                            title={`1 bài test bằng ${
                              currentUser.creditRate?.["DISC"] || 1
                            } Credit`}
                          >
                            <small className="text-red-600">
                              (-{currentUser.creditRate?.["DISC"] || 1} Credit)
                            </small>
                          </Tooltip>
                        </Checkbox>
                      </Col>
                      <Col span={12}>
                        <Checkbox
                          value="Motivators"
                          style={{ lineHeight: "32px" }}
                        >
                          Motivators{" "}
                          <Tooltip
                            title={`1 bài test bằng ${
                              currentUser.creditRate?.["Motivators"] || 1
                            } Credit`}
                          >
                            <small className="text-red-600">
                              (-{currentUser.creditRate?.["Motivators"] || 1}{" "}
                              Credit)
                            </small>
                          </Tooltip>
                        </Checkbox>
                      </Col>
                      <Col span={12}>
                        <Checkbox
                          value="Sale IQ Plus"
                          style={{ lineHeight: "32px" }}
                        >
                          Sale IQ Plus{" "}
                          <Tooltip
                            title={`1 bài test bằng ${
                              currentUser.creditRate?.["Sale IQ Plus"] || 1
                            } Credit`}
                          >
                            <small className="text-red-600">
                              (-{currentUser.creditRate?.["Sale IQ Plus"] || 1}{" "}
                              Credit)
                            </small>
                          </Tooltip>
                        </Checkbox>
                      </Col>
                      <Col span={12}>
                        <Checkbox value="EIQ 2" style={{ lineHeight: "32px" }}>
                          EIQ 2{" "}
                          <Tooltip
                            title={`1 bài test bằng ${
                              currentUser.creditRate?.["EIQ 2"] || 1
                            } Credit`}
                          >
                            <small className="text-red-600">
                              (-{currentUser.creditRate?.["EIQ 2"] || 1} Credit)
                            </small>
                          </Tooltip>
                        </Checkbox>
                      </Col>
                      <Col span={12}>
                        <Checkbox
                          value="Learning Styles"
                          style={{ lineHeight: "32px" }}
                        >
                          Learning Styles{" "}
                          <Tooltip
                            title={`1 bài test bằng ${
                              currentUser.creditRate?.["Learning Styles"] || 1
                            } Credit`}
                          >
                            <small className="text-red-600">
                              (-
                              {currentUser.creditRate?.["Learning Styles"] ||
                                1}{" "}
                              Credit)
                            </small>
                          </Tooltip>
                        </Checkbox>
                      </Col>
                      <Col span={12}>
                        <Checkbox
                          value="Kids DISC"
                          style={{ lineHeight: "32px" }}
                        >
                          Kids DISC{" "}
                          <Tooltip
                            title={`1 bài test bằng ${
                              currentUser.creditRate?.["Kids DISC"] || 1
                            } Credit`}
                          >
                            <small className="text-red-600">
                              (-{currentUser.creditRate?.["Kids DISC"] || 1}{" "}
                              Credit)
                            </small>
                          </Tooltip>
                        </Checkbox>
                      </Col>
                    </Row>
                  </Checkbox.Group>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="language">
                  <Radio.Group>
                    <Radio value="Vietnamese">Vietnamese</Radio>
                    <Radio value="English">English</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>

            <Form.Item>
              <Button
                style={{ float: "right" }}
                type="primary"
                htmlType="submit"
                icon={<SendOutlined />}
              >
                Gửi bài đánh giá
              </Button>
            </Form.Item>
          </Form>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <h3>Nội dung email: </h3>
          <Card>
            {assessmentState ? (
              <div dangerouslySetInnerHTML={{ __html: assessmentState }} />
            ) : (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}
          </Card>
        </Col>
      </Row>
      {/* 
      <Button
          onClick={handleAdd}
          type="primary"
          style={{
            marginBottom: 16,
          }}
        >
          Add a row
        </Button>
      </Space>
      <Table
        id="MultipleAdd"
        components={components}
        rowClassName={() => "editable-row"}
        dataSource={dataSource}
        columns={columns}
      /> 
      */}
    </div>
  );
}

export default MultipleAddAssessment;
