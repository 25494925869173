import {
    CaretLeftOutlined,
    CaretRightOutlined,
    QuestionOutlined,
} from "@ant-design/icons";
import { Button, Collapse, ConfigProvider, Progress, Radio, Table } from "antd";
import React, { useState } from "react";
import { useParams } from "react-router-dom";

function Shortv2(props) {
    const { short } = useParams();
    const [numQuestion, setNumQuestion] = useState(1);
    const [most, setMost] = useState();
    const [least, setLeast] = useState();
    const min = 1;
    const max = 30;
    const [question, setQuestion] = useState([
        {
            title: "Biết lắng nghe",
            description: "Chân thành, chăm chú, tập trung",
            id: "1",
        },
        {
            title: "Bền bỉ",
            description: "Quyết tâm, hăng say, bền bỉ",
            id: "2",
        },
        {
            title: "Điềm đạm",
            description: "Bình tĩnh, lý trí, có óc suy xét",
            id: "3",
        },
        {
            title: "Nhẹ nhàng",
            description: "Biết kiềm chế, trầm lặng, kín đáo",
            id: "4",
        },
    ]);
    const next = () => {
        setNumQuestion(numQuestion + 1);
    };
    const prev = () => {
        setNumQuestion(numQuestion - 1);
    };
    return (
        <>
            <ConfigProvider
                theme={{
                    components: {
                        Radio: {
                            buttonSolidCheckedActiveBg: "#15803d",
                            buttonSolidCheckedBg: "#16a34a",
                            buttonSolidCheckedHoverBg: "#22c55e",
                        },
                    },
                }}
            >
                <div className="m-4">
                    <h2>
                        Đánh giá trực tuyến DISC <QuestionOutlined />
                    </h2>
                    <div className="bg-gray-100 w-full p-7">
                        <h3>Hướng dẫn làm bài đánh giá</h3>
                        <h4>
                            Nhấp vào nút bên cạnh từ mô tả{" "}
                            <span className="text-green-700 font-bold ">
                                GIỐNG NHẤT
                            </span>{" "}
                            và{" "}
                            <span className="text-red-600 font-bold ">
                                ÍT GIỐNG NHẤT
                            </span>{" "}
                            về bạn.
                        </h4>
                        <div className="flex justify-end gap-3 mb-3">
                            <Button
                                onClick={prev}
                                disabled={min >= numQuestion}
                            >
                                <CaretLeftOutlined /> Trước đó
                            </Button>
                            <Button
                                onClick={next}
                                disabled={max <= numQuestion}
                            >
                                Tiếp theo <CaretRightOutlined />
                            </Button>
                        </div>
                        <Progress
                            percent={(numQuestion / max) * 100}
                            format={(percent) => `${numQuestion} / ${max}`}
                            percentPosition={{
                                align: "center",
                                type: "inner",
                            }}
                            size={["100%", 20]}
                            strokeColor="#428bca"
                            strokeLinecap="butt"
                            className="first:[&>.ant-progress-outer>.ant-progress-inner]:!rounded-md mb-4"
                        />
                        <Table
                            columns={[
                                {
                                    title: "",
                                    dataIndex: "title",
                                    render: (text, record) => (
                                        <>
                                            <h2 className="mb-0">{text}</h2>
                                            <span>{record.description}</span>
                                        </>
                                    ),
                                },
                                {
                                    title: (
                                        <span className="text-green-700 font-bold ">
                                            GIỐNG NHẤT
                                        </span>
                                    ),
                                    width: 140,
                                    align: "center",
                                    render: (text, record) => (
                                        <Radio
                                            value={record.id}
                                            checked={most === record.id}
                                            onChange={() => {
                                                setMost(record.id);
                                                if (least === record.id)
                                                    setLeast();
                                            }}
                                            className="[&>span.ant-radio-checked>span.ant-radio-inner]:!bg-green-700 [&>span.ant-radio>span.ant-radio-inner]:!w-5  [&>span.ant-radio>span.ant-radio-inner]:h-5"
                                            name="yes"
                                        />
                                    ),
                                },
                                {
                                    title: (
                                        <span className="text-red-600 font-bold ">
                                            ÍT GIỐNG NHẤT
                                        </span>
                                    ),
                                    width: 140,
                                    align: "center",
                                    render: (text, record) => (
                                        <Radio
                                            size="large"
                                            value={record.id}
                                            checked={least === record.id}
                                            onChange={() => {
                                                setLeast(record.id);
                                                if (most === record.id)
                                                    setMost();
                                            }}
                                            className="[&>span.ant-radio-checked>span.ant-radio-inner]:!bg-red-700 [&>span.ant-radio>span.ant-radio-inner]:!w-5  [&>span.ant-radio>span.ant-radio-inner]:h-5"
                                            name="no"
                                        />
                                    ),
                                },
                            ]}
                            bordered
                            dataSource={question}
                            rowKey="id"
                            pagination={false}
                        ></Table>
                    </div>
                </div>
                <Collapse
                    items={[
                        {
                            key: "debug",
                            label: "Debug",
                            children: (
                                <div className="m-5">
                                    <pre>
                                        {JSON.stringify(
                                            { short, numQuestion },
                                            null,
                                            2
                                        )}
                                    </pre>
                                </div>
                            ),
                        },
                    ]}
                />
            </ConfigProvider>
        </>
    );
}

export default Shortv2;
