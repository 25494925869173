import React, { useContext, useEffect, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import { Card, Space, Spin, Table, Tag } from "antd";
import { RealmContext } from "../context/realmProvider";
import { selectUser, selectUsers } from "../redux/assessmentsSlice";
import { useSelector } from "react-redux";

function Report(props) {
  const [loading, setLoading] = useState(false);
  const currentUser = useSelector(selectUser);
  //const { mongo } = useContext(RealmContext);
  const users = useSelector(selectUsers);
  const isAdmin = currentUser?.group == "Admin";
  const columns = [
    {
      title: "Fullname",
      dataIndex: "fullname",
      sorter:   (a, b) => {
        if (a.fullname < b.fullname) {
          return -1;
        }
        if (a.fullname > b.fullname) {
          return 1;
        }
        return 0;
      },
      key: "fullname",
    },
    {
      title: "Tài khoản",
      dataIndex: "email",
      sorter:  (a, b) => {
        if (a.email < b.email) {
          return -1;
        }
        if (a.email > b.email) {
          return 1;
        }
        return 0;
      },
      key: "email",
    },
    {
      title: "Credit",
      dataIndex: "credit",
      sorter:  (a, b) => a.credit - b.credit,
      key: "credit",
    },
  ];

  return (
    <Spin spinning={loading} size="large">
      <Card>
        <Link to="/">Back</Link>
        <Table
          rowKey="_id"
          className="pt-3"
          columns={columns}
          dataSource={
            isAdmin
              ? users
              : users.filter((m) => m?.parentID == currentUser.userID)
          }
          pagination={{
            pageSizeOptions: [10, 20, 50, 100, 200],
            showSizeChanger: true,
            showQuickJumper: true,
          }}
        />
      </Card>
    </Spin>
  );
}

export default Report;
