import { ArrowRightOutlined } from "@ant-design/icons";
import { Button, Popconfirm, Select, Tooltip } from "antd";
import React, { useContext, useState } from "react";
import { useSelector } from "react-redux";
import { RealmContext } from "../../context/realmProvider";
import { selectUser, selectUsers } from "../../redux/assessmentsSlice";

function Move({ entity }) {
    const [open, setOpen] = useState(false);
    const [userTo, setUserTo] = useState();
    const [confirmLoading, setConfirmLoading] = useState(false);
    const currentUser = useSelector(selectUser);
    const users = useSelector(selectUsers);
    const { user } = useContext(RealmContext);
    const showPopconfirm = () => {
        setOpen(true);
    };
    const handleOk = async () => {
        setConfirmLoading(true);
        await user.functions.moveReport({
            toUser: userTo,
            short: entity.short,
        });
        setOpen(false);
        setConfirmLoading(false);
    };
    const onChange = (value) => {
        setUserTo(value);
    };

    const handleCancel = () => {
        setOpen(false);
    };
    const filterOption = (input, option) =>
        (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
    return (
        <>
            <Tooltip title="Di chuyển">
                <Popconfirm
                    title="Di chuyển bài test"
                    description={
                        <>
                            <Select
                                showSearch
                                placeholder="Chọn tài khoản"
                                optionFilterProp="children"
                                className="min-w-[200px]"
                                allowClear
                                onChange={onChange}
                                filterOption={filterOption}
                                options={users?.map((u) => ({
                                    value: u.userID,
                                    label: u.fullname,
                                }))}
                            />
                        </>
                    }
                    open={open}
                    onConfirm={handleOk}
                    okButtonProps={{
                        loading: confirmLoading,
                    }}
                    onCancel={handleCancel}
                >
                    <Button
                        icon={<ArrowRightOutlined />}
                        onClick={showPopconfirm}
                        size="small"
                        type="primary"
                    ></Button>
                </Popconfirm>
            </Tooltip>
        </>
    );
}

export default Move;
