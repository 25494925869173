import { Button, Card, Checkbox, Col, Form, Space, Row } from "antd";
import React from "react";
import Assessments from "../components/dashboard/Assessments";
import MultipleAddAssessment from "../components/dashboard/MultipleAddAssessment";
import QuickAddAssessment from "../components/dashboard/QuickAddAssessment";
import TopNewAssessments from "../components/dashboard/TopNewAssessments";
import { selectUser } from "../redux/assessmentsSlice";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

function Dashboard(props) {
  const currentUser = useSelector(selectUser);
  const isAdmin = currentUser?.group == "Admin";
  return (
    <>
      {currentUser?.group == "Admin" && (
        <Space className="pb-3">
          <Link to="/admin">Administrator</Link>
          <Link to="/report">Report</Link>
          <Link to="/combo">Combo</Link>
        </Space>
      )}
      {currentUser?.email == "chc@a247.io" && <Link to="/report">Report</Link>}
      <Space direction="vertical" size="middle">
        <Card>
          <QuickAddAssessment />
        </Card>

        {/* {isAdmin ? (
          <Card>
            <MultipleAddAssessment />
          </Card>
        ) : null} */}
      </Space>
      <Row style={{ marginTop: "20px" }}>
        <Col span={24}>
          <Assessments />
        </Col>
      </Row>
    </>
  );
}

export default Dashboard;
