import { RetweetOutlined } from "@ant-design/icons";
import { Button, Form, Input, message, Popconfirm, Tooltip } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { RealmContext } from "../../context/realmProvider";
import { updateAssessments } from "../../redux/assessmentsSlice";
import { isValidUrl, ObjectId } from "../../utils";

function Transfer({ entity }) {
    const { mongo } = useContext(RealmContext);
    const [download, setDownload] = useState(null);
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [open, setOpen] = useState(false);

    const handleUpdate = (id, download) => {
        const ids = [];
        ids.push(ObjectId(id));
        dispatch(
            updateAssessments({
                ids,
                mongo,
                update: {
                    download,
                },
            })
        );
        setOpen(false);
    };
    useEffect(() => {
        if (entity.download) form.setFieldsValue({ URL: entity.download });
    }, [entity.download]);
    return (
        <>
            <Form
                form={form}
                className="min-w-[300px] inline"
                initialValues={{ URL: entity.download }}
                onFinish={(values) => {
                    handleUpdate(entity._id, values.URL);
                }}
            >
                <Tooltip title="Lưu trữ báo cáo">
                    <Popconfirm
                        title="Nhập URL báo cáo"
                        open={open}
                        description={
                            <Form.Item
                                name="URL"
                                rules={[
                                    {
                                        required: false,
                                    },
                                    {
                                        type: "url",
                                    },
                                    {
                                        type: "string",
                                        min: 6,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        }
                        onConfirm={() => form.submit()}
                        onCancel={() => setOpen(false)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button
                            size="small"
                            type="primary"
                            className="mr-1"
                            icon={<RetweetOutlined />}
                            danger={!entity.download}
                            onClick={() => setOpen(true)}
                        ></Button>
                    </Popconfirm>
                </Tooltip>
            </Form>
        </>
    );
}

export default Transfer;
